// extracted by mini-css-extract-plugin
export var howItWorksBothContainer = "how_it_works_both-module--howItWorksBothContainer--72e86";
export var howItWorksBothHeading = "how_it_works_both-module--howItWorksBothHeading--a78a0";
export var howItWorksBothImage = "how_it_works_both-module--howItWorksBothImage--828f0";
export var howItWorksBothLineLong = "how_it_works_both-module--howItWorksBothLineLong--1b62a";
export var howItWorksBothLineShort = "how_it_works_both-module--howItWorksBothLineShort--57fab";
export var howItWorksBothNumberLabel = "how_it_works_both-module--howItWorksBothNumberLabel--06cf2";
export var howItWorksBothSection = "how_it_works_both-module--howItWorksBothSection--3c9a0";
export var howItWorksBothSubHeading = "how_it_works_both-module--howItWorksBothSubHeading--5a86d";
export var howItWorksBothSubSubHeading = "how_it_works_both-module--howItWorksBothSubSubHeading--fc708";
export var howItWorksDetailsContainer = "how_it_works_both-module--howItWorksDetailsContainer--d6c44";