import React from 'react'
import Typography from '../ui/typography'
import Section from '../ui/section'
import Container from '../ui/container'
import * as styles from './two_title_with_cta_right_image.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import BlueArrow from '../../../static/assets/img/arrow-top-right-blue.svg'
import AppLinks from '../app_links/app_links'

const { Paragraph, Link } = Typography

export default (props) => (
  <div className="bg-white">
    <Container className={styles.twoTitleWithCtaRightImageContainer}>
      {/* <h2 className={styles.twoTitleWithCtaRightImageHeading}>
        {props.data.heading}
      </h2> */}
      <div className="flex flex-col-reverse w-full lg:flex-row">
        <div className={styles.twoTitleWithCtaRightImageDetailsContainer}>
          <h3 className={styles.twoTitleWithCtaRightImageSubTitle}>
            {props.data.subHeading}
          </h3>
          <Paragraph className={styles.twoTitleWithCtaRightImageParagraph}>
            {props.data.paragraphText.paragraphText}
          </Paragraph>
          <Link
            href={props.data.ctaLink.url}
            className="flex items-center gap-2 mb-8"
          >
            {props.data.ctaLink.label}
            <img
              alt="Blue arrow pointing up"
              src={BlueArrow}
              className="w-3 h-3"
            />
          </Link>
          <div className="mx-auto max-w-xs lg:mx-0">
            <AppLinks
              assets={props.assets}
              browserLink={false}
              isBlack={true}
            />
          </div>
        </div>
        <div className="w-full flex justify-center lg:w-1/2">
          <GatsbyImage
            alt={props.data.imageRight.title}
            imgStyle={{
              objectFit: 'contain',
              objectPosition: 'center',
            }}
            className={styles.twoTitleWithCtaRightImageFeaturedImage}
            image={props.data.imageRight.gatsbyImageData}
          />
        </div>
      </div>
    </Container>
  </div>
)
