import React from 'react'
import Typography from '../ui/typography'
import Section from '../ui/section'
import Container from '../ui/container'
import * as styles from './one_title_right_image.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default (props) => (
  <Section className="bg-white">
    <Container className={styles.oneTitleRightImageContainer}>
      <div className={styles.oneTitleRightImageDetails}>
        <h2 className={styles.oneTitleRightImageHeading}>
          {props.data.heading}
        </h2>
        <Paragraph>{props.data.paragraphText.paragraphText}</Paragraph>
      </div>
      <GatsbyImage
        alt={props.data.rightImageAlt}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        image={props.data.rightImage.gatsbyImageData}
        className={styles.oneTItleRightImageImage}
      />
    </Container>
  </Section>
)
