import React from 'react'
import Typography from '../ui/typography'
import Section from '../ui/section'
import Container from '../ui/container'
import * as styles from './extended_eligibility.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default (props) => (
  <div className="bg-white">
    <Container className={styles.extendedEligibilityContainer}>
      <div className={styles.extendedEligibilityDetails}>
        <h2 className={styles.extendedEligibilityHeading}>
          {props.data.heading}
        </h2>
        <Paragraph className="mb-6">
          {props.data.paragraphText1.paragraphText1}
        </Paragraph>
        <div className="flex flex-col gap-2 mb-6">
          <h3 className={styles.extendedEligibilitySubHeading}>
            {props.data.subHeading1}
          </h3>
          {props.data.requirements.map((requirement) => {
            return (
              <div className="flex gap-4 -ml-2">
                <GatsbyImage
                  alt={props.data.bulletImage1.title}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                  image={props.data.bulletImage1.gatsbyImageData}
                  className="w-4 h-4 shrink-0 mt-2.5"
                ></GatsbyImage>
                <Paragraph>{requirement}</Paragraph>
              </div>
            )
          })}
        </div>
        <div className="flex flex-col gap-2 mb-6">
          <h3 className={styles.extendedEligibilitySubHeading}>
            {props.data.subHeading2}
          </h3>
          {props.data.eligibilityHints.map((eligibilityHint) => {
            return (
              <div className="flex gap-4 -ml-2">
                <GatsbyImage
                  alt={props.data.bulletImage2.title}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                  image={props.data.bulletImage2.gatsbyImageData}
                  className="w-4 h-4 shrink-0 mt-2.5"
                ></GatsbyImage>
                <Paragraph>{eligibilityHint}</Paragraph>
              </div>
            )
          })}
        </div>
        <Paragraph>{props.data.paragraphText2.paragraphText2}</Paragraph>
      </div>
      <GatsbyImage
        alt={props.data.rightImage.title}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        image={props.data.rightImage.gatsbyImageData}
        className={styles.extendedEligibilityImage}
      />
    </Container>
  </div>
)
