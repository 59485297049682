import React from 'react'
import Typography from '../ui/typography'
import Section from '../ui/section'
import Container from '../ui/container'
import * as styles from './one_title_left_image.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default (props) => (
  <Section className="bg-white">
    <Container className={styles.oneTitleLeftImageContainer}>
      <GatsbyImage
        alt={props.data.leftImageAlt}
        imgStyle={{
          objectFit: 'contain',
          objectPosition: 'center',
        }}
        image={props.data.leftImage.gatsbyImageData}
        className={styles.oneTItleLeftImageImage}
      />

      <div className={styles.oneTitleLeftImageDetails}>
        <h2 className={styles.oneTitleLeftImageHeading}>
          {props.data.heading}
        </h2>
        <Paragraph className="whitespace-pre-wrap">
          {props.data.paragraphText.paragraphText}
        </Paragraph>
      </div>
    </Container>
  </Section>
)
