import React from 'react'
import Typography from '../ui/typography'
import Section from '../ui/section'
import Container from '../ui/container'
import * as styles from './how_it_works_both.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'

const { Paragraph } = Typography

export default (props) => {
  const howItWorksList = [
    {
      image: props.data.leftImage,
      imageExtraClass: '-ml-28 lg:-ml-56',
      heading: props.data.leftSubSubHeading,
      paragraph: props.data.leftParagraphText.leftParagraphText,
      pillText: props.data.leftPillHeading,
    },
    {
      image: props.data.rightImage,
      imageExtraClass: '-ml-4 lg:-ml-12',
      heading: props.data.rightSubSubHeading,
      paragraph: props.data.rightParagraphText.rightParagraphText,
      pillText: props.data.rightPillHeading,
    },
  ]

  return (
    <Section className={styles.howItWorksBothSection}>
      <Container className={styles.howItWorksBothContainer}>
        <h2 className={styles.howItWorksBothHeading}>{props.data.heading}</h2>
        <h3 className={styles.howItWorksBothSubHeading}>
          {props.data.subHeading}
        </h3>

        <div className="flex flex-col justify-between w-full gap-12 lg:flex-row">
          {howItWorksList.map((howItWorks, idx) => {
            return (
              <div key={idx} className="lg:max-w-[500px] flex flex-col items-center">
                <div className={styles.howItWorksBothNumberLabel}>
                  {howItWorks.pillText}
                </div>
                <div className="flex flex-col space-y-2 lg:space-y-4">
                  <div
                    className={`hidden lg:block ${styles.howItWorksBothLineLong}`}
                  ></div>
                  <div className={styles.howItWorksBothLineLong}></div>
                  <div className={styles.howItWorksBothLineShort}></div>
                </div>
                <GatsbyImage
                  alt={howItWorks.image.title}
                  imgStyle={{
                    objectFit: 'contain',
                    objectPosition: 'center',
                  }}
                  className={`${howItWorks.imageExtraClass} ${styles.howItWorksBothImage}`}
                  image={howItWorks.image.gatsbyImageData}
                />
                <div className={styles.howItWorksDetailsContainer}>
                  <h4 className={styles.howItWorksBothSubSubHeading}>
                    {howItWorks.heading}
                  </h4>
                  {/* Split by new lines separated by margin */}
                  {howItWorks.paragraph.split('\n').map((newRow, idx) => (
                    <Paragraph key={idx} className="mb-1.5">{newRow}</Paragraph>
                  ))}
                </div>
              </div>
            )
          })}
        </div>
      </Container>
    </Section>
  )
}
