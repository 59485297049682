import React from 'react'
import TwoTitleWithCtaRightImage from '../two_title_with_cta_right_image/two_title_with_cta_right_image'
import HowItWorksBoth from '../how_it_works_both/how_it_works_both'
import OneTitleLeftImage from '../one_title_left_image/one_title_left_image'
import OneTitleRightImage from '../one_title_right_image/one_title_right_image'
import Testimonial from '../testimonial/testimonial'
import FAQContent from '../faq_content/faq_content'
import ExtendedEligibility from '../extended_eligibility/extended_eligibility'
import DownloadCtaSimple from '../download_cta_simple/download_cta_simple'
import Explanation from '../explanation/explanation'

export default (props) => (
  <div>
    {props.sections.map((section) => {
      if (!section.internal) return ''
      switch (section.internal.type) {
        case 'ContentfulGenericTwoTitleWithCtaRightImage':
          return (
            <TwoTitleWithCtaRightImage
              data={section}
              assets={props.assets}
            ></TwoTitleWithCtaRightImage>
          )
        case 'ContentfulHowItWorksBoth':
          return <HowItWorksBoth data={section}></HowItWorksBoth>
        case 'ContentfulGenericOneTitleLeftImage':
          return <OneTitleLeftImage data={section}></OneTitleLeftImage>
        case 'ContentfulGenericOneTitleRightImage':
          return <OneTitleRightImage data={section}></OneTitleRightImage>
        case 'ContentfulExtendedEligibility':
          return <ExtendedEligibility data={section}></ExtendedEligibility>
        case 'ContentfulDownloadCtaSimple':
          return (
            <DownloadCtaSimple
              data={section}
              assets={props.assets}
            ></DownloadCtaSimple>
          )
        case 'ContentfulTestimonialContent':
          return <Testimonial data={section}></Testimonial>
        case 'ContentfulFaqContent':
          return <FAQContent data={section}></FAQContent>
        case 'ContentfulProductListContent':
          return <Explanation data={section}></Explanation>
        default:
          return ''
      }
    })}
  </div>
)
