import React from 'react'
import Typography from '../ui/typography'
import Container from '../ui/container'
import * as styles from './download_cta_simple.module.css'
import { GatsbyImage } from 'gatsby-plugin-image'
import AppLinks from '../app_links/app_links'

const { Paragraph } = Typography

export default (props) => (
  <Container className={styles.downloadCtaSimpleContainer}>
    <div className={styles.downloadCtaSimpleDetails}>
      <h2 className={styles.downloadCtaSimpleHeading}>{props.data.heading}</h2>
      <Paragraph className="leading-relaxed">
        {props.data.paragraphText.paragraphText}
      </Paragraph>
    </div>
    <GatsbyImage
      alt={props.data.imageAlt}
      imgStyle={{
        objectFit: 'contain',
        objectPosition: 'center',
      }}
      image={props.data.rightImage.gatsbyImageData}
      className={styles.downloadCtaSimpleImage}
    />
    <div className="max-w-xs lg:hidden">
      <AppLinks assets={props.assets} browserLink={false} isWhite={true} />
    </div>
  </Container>
)
